import { Row, Col } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import { PngIcon } from "../../common/PngIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = () => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Title>{"Alamat"}</Title>
              <Para>Jl. Pandega Marta, Sleman</Para>
              <Para>DI Yogyakarta</Para>
              <Para>info[at]smpiyaabunayya.id</Para>
              <Para>+62 812-9604-2874 (Faris)</Para>
              <Para>&nbsp;</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{"Link penting"}</Title>
              <Large left="true" to="/">
                {"About"}
              </Large>
              <Large left="true" to="/">
                {"Blog"}
              </Large>
              <Large left="true" to="/">
                {"Press"}
              </Large>
              <Large left="true" to="/">
                {"Careers & Culture"}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
                <Para>SMPI YaaBunayya di bawah naungan</Para>
                <br/>
                <p><a href="https://ypia.or.id">Yayasan Pendidikan Islam Al Atsary</a></p>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <PngIcon src="smp-logo-full.jpeg" width="188px" height="55px" />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default (Footer);
